<template>
  <a-carousel arrows dots-class="slick-dots slick-thumb">
    <template>
      <a href="/free-product?act_id=1">
        <img style="width: 100%;" src="https://img.fantaskycdn.com/7176e673032a71e998d89c2847f4b993_1920x.png" />
      </a>
    </template>
    <div v-for="item in 4" :key="item">
      <img style="width: 100%;" src="https://img.fantaskycdn.com/7176e673032a71e998d89c2847f4b993_1920x.png" />
    </div>
  </a-carousel>
<!--  <a-carousel arrows>
    <template #prevArrow>
      <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
        <left-circle-outlined />
      </div>
    </template>
    <template #nextArrow>
      <div class="custom-slick-arrow" style="right: 10px">
        <right-circle-outlined />
      </div>
    </template>
    <div>
      <h3>111111111</h3>
    </div>
    <div><h3>2222222</h3></div>
    <div><h3>3333333</h3></div>
    <div><h3>4444444</h3></div>
  </a-carousel>-->
</template>

<script>
export default {
  name: "HomePage"
}
</script>

<style scoped>
/* For demo */
:deep(.slick-dots) {
  position: relative;
  height: auto;
}
:deep(.slick-slide img) {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 100%;
}
:deep(.slick-arrow) {
  display: none !important;
}
:deep(.slick-thumb) {
  bottom: 0px;
}
:deep(.slick-thumb li) {
  width: 60px;
  height: 45px;
}
:deep(.slick-thumb li img) {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
  display: block;
}
:deep .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
</style>

<!--
<style scoped>
/* For demo */
:deep(.slick-slide) {
  text-align: center;
  height: auto;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

:deep(.slick-arrow.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  transition: ease all 0.3s;
  opacity: 0.3;
  z-index: 1;
}
:deep(.slick-arrow.custom-slick-arrow:before) {
  display: none;
}
:deep(.slick-arrow.custom-slick-arrow:hover) {
  color: #fff;
  opacity: 0.5;
}

:deep(.slick-slide h3) {
  color: #fff;
}
</style>-->
