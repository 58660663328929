<template>
  <Header/>
  <SignIn v-if="showLogin"/>
</template>

<script>
import SignIn from "@/views/website/SignIn";
import {onMounted, provide, ref} from "vue";
import Header from "@/views/Header";

export default {
  name: 'App',
  components: {
    Header,
    SignIn,
  },

  setup() {
    console.log(process.env.VUE_APP_TITLE);
    const showLogin = ref(true);
    // 判断是否登录
    let authState = localStorage.getItem('authState');
    if (authState) {
      authState = JSON.parse(authState);
      if (authState != null) {
        showLogin.value = !authState.isLoggedIn;
      }
    } else {
      showLogin.value = true;
    }

    provide('authState', authState);
    provide('showLogin', showLogin);

    return {
      showLogin,
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
