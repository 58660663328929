<template>
  <div>
    <!--<a-button type="primary" @click="showModal">Open Modal with customized footer</a-button>-->
    <a-modal v-model:open="open" title="Email verification" @ok="handleOk" :keyboard="false" :maskClosable="false"
             :bodyStyle="{ paddingTop: '30px' }" :centered="true" :closable="false"
    >
      <template #footer>
        <!-- <a-button key="back" @click="handleCancel">Return</a-button>-->
        <div style="display: flex; justify-content: center;">
          <a-button key="submit" type="primary" :loading="loading" @click="handleOk">Submit</a-button>
        </div>
      </template>
      <a-form
          :model="formState"
          v-bind="layout"
          name="nest-messages"
          :validate-messages="validateMessages"
          @finish="onFinish"
      >
        <a-form-item :name="['email']" label="Your Email" :rules="[{ required: true, type: 'email' }]">
          <a-input v-model:value="formState.email" />
        </a-form-item>
        <a-form-item :name="['verifyCode']" label="Verify Code" :rules="[{ required: true }]">
          <a-input v-model:value="formState.verifyCode" :style="{ width: '140px' }"/>
          <!--<span class="ant-form-text"> 发送验证码</span>-->
          <a-button type="link" @click="sendVerifyCode">{{ codeMsg }}</a-button>
          <a-space direction="vertical" style="margin-top: 10px;">
            <a-badge status="processing" text="Haven't received the verification code yet? Please check your junk email." />
          </a-space>
          <!--<span v-if="countdown > 0">{{ countdown }}</span>-->
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script setup>
import { ref, reactive, onUnmounted  } from 'vue';
import {sendEmailCode, useAuth} from "@/services/userAuth";
import { message } from 'ant-design-vue';

const loading = ref(false);
const open = ref(true);
/*const showModal = () => {
  open.value = true;
};*/
const handleOk = async () => {
  loading.value = true;

  // 登录
  const res = await useAuth().login(formState);

  console.log('Login Success', res)

  if (res.code === 200) {
    loading.value = false;
    open.value = false;

    location.reload()
  } else {
    loading.value = false;
  }
};
const handleCancel = () => {
  open.value = false;
};


const countdown = ref(0);
const codeMsg = ref('Send verification code');
let intervalId;
const startCountdown = () => {
  countdown.value = 60;
  codeMsg.value = countdown.value + ' s';
  intervalId = setInterval(() => {
    countdown.value--;
    codeMsg.value = countdown.value + ' s';
    if (countdown.value <= 0) {
      clearInterval(intervalId);
      codeMsg.value = 'Send verification code';
    }
  }, 1000);
};

onUnmounted(() => {
  clearInterval(intervalId);
});
const sendVerifyCode = () => {
  console.log(countdown.value)
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!emailRegex.test(formState.email)) {
    message.error('Please enter a valid email address');
    return;
  }
  if (countdown.value > 0) {
    return;
  }

  // 发送验证码
  const data = sendEmailCode(formState);

  if (countdown.value <= 0) {
    startCountdown();
  }

}

const fetchData = async () => {
  try {
    users.value = await fetchUsers();
    userDetails.value = await fetchUserDetails(users.value[0].id);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};


const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid verify code!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
const formState = reactive({
  email: '',
  verifyCode: '',
});
const onFinish = values => {
  console.log('Success:', values);
};
</script>