<template>
  <a-layout class="layout">
    <a-layout-content style="padding: 0 50px">
      <a-breadcrumb style="margin: 16px 0">
      </a-breadcrumb>
      <div :style="{ background: '#fff', padding: '24px', minHeight: '280px' }">
        <a-menu v-model:selectedKeys="currentMenu" mode="horizontal" :items="items"/>
        <a-table
            :columns="columns"
            :row-key="record => record.id"
            :data-source="data"
            :pagination="pagination"
            :loading="loading"
            @change="handleTableChange"
            style="margin-top: 10px;"
            :show-total="total => `Total ${total} items`"
        >
          <template #headerCell="{ column }">
            <template v-if="column.key === 'name'">
              <span>Name</span>
            </template>
          </template>

          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'name'">
              {{ record.name }}
            </template>
            <template v-else-if="column.key === 'action'">
              <span>
                <a :href="'/free-product?record_id=' + record.id">Detail</a>
              </span>
            </template>
          </template>
        </a-table>
      </div>
    </a-layout-content>
    <a-layout-footer style="text-align: center; height: 0px;">
    </a-layout-footer>
  </a-layout>
</template>
<script setup>
import {computed, h, ref} from 'vue';
import { usePagination } from 'vue-request';
import {allList} from "@/services/freeProduct";
import {message} from "ant-design-vue";

const currentMenu = ref(['all-list']);
const items = ref([
  {
    key: 'free-product',
    label: h(
        'a',
        {
          href: '/free-product',
        },
        'Free Product Application'
    ),
    title: 'Free Product Application',
  },
  {
    key: 'all-list',
    label: h(
        'a',
        {
          href: '/all-list',
        },
        'All applications',
    ),
    title: 'All applications',
  },
]);

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    width: '20%',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Order Id',
    dataIndex: 'order_no',
    key: 'order_no',
  },
  {
    title: 'Created Time',
    dataIndex: 'created_at',
    key: 'created_at',
  },
  {
    title: 'Action',
    key: 'action',
  },
];

const data = ref([]);
// 分页配置
const pagination = ref({
  current: 1,
  pageSize: 10,
  total: 0, // 这里通常是通过后端接口获取的总记录数
});

const queryData = async params => {
  console.log('==query data params', params)
  const res = await allList(params);
  console.log('===query res', res);
  if (res.code === 200) {
    data.value = res.data.data;
    pagination.value.total = res.data.total;
    pagination.value.current = res.data.current_page
  } else {
    message.error(res.msg);
  }
  return res;
};
const {
  data: dataSource,
  run,
  loading,
  current,
  pageSize,
} = usePagination(queryData, {
  formatResult: res => res.data.data,
  pagination: {
    currentKey: 'page',
    pageSizeKey: 'results',
  },
});
/*const pagination = computed(() => ({
  total: 0,
  current: current.value,
  pageSize: pageSize.value,
}));*/
console.log('===dataSource', dataSource);
const handleTableChange = (pag, filters, sorter, extra) => {
  console.log('===handleTableChange', pag, filters, sorter, extra);
  run({
    results: pag.pageSize,
    page: pag?.current,
    sortField: sorter.field,
    sortOrder: sorter.order,
    ...filters,
  });
};

</script>
<style scoped>
.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

[data-theme='dark'] .site-layout-content {
  background: #141414;
}
</style>