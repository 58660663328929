import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/HomePage.vue';
import FreeProduct from '../views/website/FreeProduct';
import AllList from "../views/website/AllList";
import TestPage from "../views/website/TestPage";
import SignIn from "../views/website/SignIn";


const routes = [
    {
        path: '/sign-in',
        name: 'SignIn',
        component: SignIn,
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/free-product',
        name: 'FreeProduct',
        component: FreeProduct,
    },
    {
        path: '/all-list',
        name: 'AllList',
        component: AllList,
    },
    {
        path: '/test-page',
        name: 'TestPage',
        component: TestPage,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});


export default router;