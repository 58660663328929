// src/http.js
import axios from 'axios';

let authState = localStorage.getItem('authState');
if (authState) {
    authState = JSON.parse(authState);
} else {

}
console.log('====authState', authState)
const http = axios.create({
    baseURL: process.env.VUE_APP_API_URL, // 你的API端点
    headers: {
        'Content-Type': 'application/json',
        // 其他通用头部信息
        'Access-Token': authState != null ? authState.token : '',
    },
    // 其他通用配置
});

// 添加响应拦截器
http.interceptors.response.use(
    function (response) {
        console.log('=====http response', response)
        // 对响应数据做点什么
        // 假设你希望所有请求都返回 data 字段的内容
        // 并且如果返回了特定的错误码，你想抛出一个错误
        const { data, status } = response;

        if (data.code === 401) {
            // 未授权状态
            console.log('===Not logged in====');
            localStorage.removeItem('authState');
        }

        // 这里的处理逻辑取决于你的后端API如何设计
        // 例如，如果后端在发生错误时返回了一个带有错误信息的 data 对象
        if (status !== 200) {
            // 如果不是200，可能需要根据具体业务逻辑处理错误
            return Promise.reject(new Error(data.message || 'Error'));
        } else {
            return response;
        }

        // 假设返回的数据结构是 { code: 200, message: 'Success', data: {...} }
        // 并且你想返回 data 字段而不是整个响应对象
        /*if (data.code === 200) {
            return data.data; // 只返回 data 字段
        }*/

        // 如果返回了其他错误码，你可能想要抛出一个具体的错误
        //return Promise.reject(new Error(data.message || 'Unknown Error'));
    },
    function (error) {
        // 对响应错误做点什么
        // 这里你可以统一处理请求错误，比如网络错误、超时等
        // 也可以在这里进行错误信息的格式化或转换

        // 例如，你可以根据需求返回特定的错误对象或信息
        //return Promise.reject(error);
    }
);

export default http;