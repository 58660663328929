<template>
  <a-layout class="layout">
    <a-layout-content style="padding: 0 50px">
      <a-breadcrumb style="margin: 16px 0">
      </a-breadcrumb>
      <div :style="{ background: '#fff', padding: '24px', minHeight: '280px' }">
        <a-menu v-model:selectedKeys="current" mode="horizontal" :items="items"/>
        <div class="load-div" v-show="stepLoading">
          <a-spin size="large" />
        </div>
        <div v-show="!stepLoading">
          <a-steps
              v-model:current="currentStep"
              status="success"
              :style="{ padding: '40px' }"
              :items="[
              {
                title: 'Step 1',
                description: 'Free product shipping address',
              },
              {
                title: 'Step 2',
                description: 'Input your screen shoot of your bought record',
              },
              {
                title: 'Step 3',
                description: 'What do you think of the product',
              },
              {
                title: 'Step 4',
                description: 'Free qualification confirmation',
              },
              {
                title: 'Step 5',
                description: 'Arrange shipment',
              },
            ]"
          ></a-steps>


          <!----------------------------Step 1 Form----------------------------->
          <a-form
              :model="formState1"
              name="validate_other"
              v-bind="formItemLayout"
              @finish="onFinish1"
              :style="{ alignItems: 'baseline' }"
              :validate-messages="validateMessages"
              v-show="currentStep === 0"
              :disabled="componentDisabled"
          >
            <a-form-item :name="['id']" label="Id" v-show="false">
              <a-input v-model:value="formState1.id"/>
            </a-form-item>

            <a-form-item :name="['name']" label="Name" :rules="[{ required: true }]">
              <a-input v-model:value="formState1.name"/>
            </a-form-item>

            <a-form-item :name="['phone']" label="Phone" :rules="[{ required: true }]">
              <a-input v-model:value="formState1.phone"/>
            </a-form-item>

            <a-form-item :name="['email']" label="Your Email" :aria-disabled="true" validate-status="success" :rules="[{ required: true }]">
              <a-input v-model:value="formState1.email" :disabled="true"/>
            </a-form-item>

            <a-form-item :name="['address']" label="Address" :rules="[{ required: true }]">
              <a-textarea v-model:value="formState1.address"/>
            </a-form-item>

            <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
              <a-button type="primary" html-type="submit" :loading="loading">Submit</a-button>
            </a-form-item>
          </a-form>


          <!----------------------------Step 2 Form----------------------------->
          <a-form
              :model="formState2"
              name="validate_other"
              v-bind="formItemLayout"
              :validate-messages="validateMessages"
              v-show="currentStep === 1"
              @finish="onFinish2"
              :disabled="componentDisabled"
          >

            <a-form-item :name="['imageList']" label="Upload" :rules="[{ required: true }]">
              <a-upload
                  name="file"
                  v-model:file-list="fileList"
                  :action="apiUrl + '/api/free-review/upload'"
                  :before-upload="beforeUpload"
                  :headers="uploadHeader"
                  list-type="picture-card"
                  @preview="handlePreview"
                  accept="image/jpeg,image/png"
                  @change="handleUploadChange"
              >
                <div v-if="fileList.length < 5">
                  <div style="margin-top: 8px">Upload</div>
                </div>
              </a-upload>
              <a-modal :open="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
                <img alt="example" style="width: 100%" :src="previewImage"/>
              </a-modal>
            </a-form-item>

            <a-form-item :name="['order_no']" label="Order Id" :rules="[{ required: true }]">
              <a-input v-model:value="formState2.order_no"/>
            </a-form-item>

            <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
              <a-button type="primary" html-type="submit" :loading="loading">Submit</a-button>
            </a-form-item>
          </a-form>


          <!----------------------------Step 3 Form----------------------------->
          <a-form
              :model="formState3"
              name="validate_other"
              v-bind="formItemLayout"
              @finish="onFinish3"
              :style="{ alignItems: 'baseline' }"
              :validate-messages="validateMessages"
              v-show="currentStep === 2"
              :disabled="componentDisabled"
          >
            <a-form-item :name="['ratings']" label="Ratings" :rules="[{ required: true }]">
              <a-rate v-model:value="formState3.ratings"/>
            </a-form-item>

            <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
              <a-button type="primary" html-type="submit" :loading="loading">Submit</a-button>
            </a-form-item>
          </a-form>


          <!----------------------------Step 4 Form----------------------------->
          <a-form
              name="validate_other"
              v-bind="formItemLayout"
              :style="{ alignItems: 'baseline' }"
              :validate-messages="validateMessages"
              v-show="currentStep === 3"
          >
            <a-result v-show="recordStatus === 'POSITIVE'"
                      title="Free qualification review in progress, please pay attention to receiving email notification of results">
            </a-result>

            <a-result
                v-show="['AUDIT_APPROVED', 'SHIPPED', 'DELIVERED'].includes(recordStatus)"
                status="success"
                title="Congratulations, you will receive a free product!"
                sub-title="We will process the shipment within 1-2 working days. Please pay attention to the shipping notification email."
            >
            </a-result>

            <a-result
                v-show="['NEGATIVE', 'AUDIT_FAILED'].includes(recordStatus)"
                status="warning"
                title="Unfortunately!"
                sub-title="You are not eligible to receive free products. Perhaps you will receive free products next time. Thank you for purchasing our products and we wish you a happy life."
            >
            </a-result>
          </a-form>


          <!----------------------------Step 5 Form----------------------------->
          <a-form
              name="validate_other"
              v-bind="formItemLayout"
              :style="{ alignItems: 'baseline' }"
              :validate-messages="validateMessages"
              v-show="currentStep === 4"
          >
            <a-result
                title="The free product has been shipped!"
                v-show="['SHIPPED', 'DELIVERED'].includes(recordStatus)"
            >
              <template #icon>
                <smile-twoTone/>
              </template>
              <template #extra>
                logistics company :
                <a-tag :bordered="false" color="lime" style="font-size: 24px; padding: 4px 8px;">{{ recordInfo.logistics_provider }}</a-tag>
                tracking number :
                <a-tag :bordered="false" color="green" style="font-size: 24px; padding: 4px 8px;">{{ recordInfo.tracking_number }}</a-tag>
              </template>
            </a-result>
          </a-form>
        </div>
      </div>
    </a-layout-content>
    <a-layout-footer style="text-align: center; height: 0px;">
    </a-layout-footer>
  </a-layout>
</template>
<script setup>
import {h, ref, reactive, inject, onMounted} from 'vue';
import {ratings, recently, recipient, screenshot} from "@/services/freeProduct";
import {message} from "ant-design-vue";

const apiUrl = process.env.VUE_APP_API_URL;
const componentDisabled = ref(false);
const loading = ref(false);
const stepLoading = ref(true);
const current = ref(['free-product']);
const currentStep = ref(0);
const recordStatus = ref('');
const actId = ref('');
const setCurrentStep = (status) => {
  // status (RECEIVER_INFO, SCREENSHOT, NEGATIVE, POSITIVE, AUDIT_FAILED, AUDIT_APPROVED, SHIPPED, DELIVERED)
  if (status === '') {
    currentStep.value = 0;
  } else if (status === 'RECEIVER_INFO') {
    currentStep.value = 1;
  } else if (status === 'SCREENSHOT') {
    currentStep.value = 2;
  } else if (['NEGATIVE', 'POSITIVE', 'AUDIT_FAILED', 'AUDIT_APPROVED'].includes(status)) {
    currentStep.value = 3;
  } else if (['SHIPPED', 'DELIVERED'].includes(status)) {
    currentStep.value = 4;
  }
  recordStatus.value = status

  // 禁止表单修改
  if (['AUDIT_APPROVED', 'SHIPPED', 'DELIVERED'].includes(status)) {
    componentDisabled.value = true
  }
};

const items = ref([
  {
    key: 'free-product',
    label: h(
        'a',
        {
          href: '/free-product',
        },
        'Free Product Application'
    ),
    title: 'Free Product Application',
  },
  {
    key: 'all-list',
    label: h(
        'a',
        {
          href: '/all-list',
        },
        'All applications',
    ),
    title: 'All applications',
  },
]);

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};


// 获取最近领取记录信息
const authState = inject('authState');
const recordInfo = reactive({});

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const formState1 = reactive({
  'id': '',
  'name': '',
  'phone': '',
  'email': authState != null ? authState.email : '',
  'address': ''
});
console.log('======formState1', formState1);
const onFinish1 = async values => {
  loading.value = true
  if (formState1.act_id == null || formState1.act_id === '') formState1.act_id = actId.value
  const res = await recipient(formState1)
  if (res.code === 200) {
    formState1.id = res.data.id
    setCurrentStep(res.data.status)
  }
  loading.value = false
};


// 第二步，图片上传
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const previewVisible = ref(false);
const previewImage = ref('');
const previewTitle = ref('');
const fileList = ref([]);
const handleCancel = () => {
  previewVisible.value = false;
  previewTitle.value = '';
};
const handlePreview = async file => {
  if (!file.url && !file.preview) {
    file.preview = await getBase64(file.originFileObj);
  }
  previewImage.value = file.url || file.preview;
  previewVisible.value = true;
  previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
};
const formState2 = reactive({
  'id': recordInfo.id,
  'order_no': recordInfo.order_no,
  'imageList': [],
});
const beforeUpload = file => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG or PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};
const handleUploadChange = async (info) => {
  loading.value = true;

  if (info.file.status === 'done') {
    // 上传成功，获取响应数据
    console.log('接口返回', info.file.response);
    if (info.file.response.code === 200) {
      info.file.url = info.file.response.data.url;
      formState2.imageList.push(info.file.response.data.url);
    } else {
      info.file.status = 'error';
      info.file.url = '';
      message.error('Upload failed');
    }
  } else if (info.file.status === 'error') {
    // 上传失败，获取错误信息
    console.error('上传失败', info.file.error);
    message.error('Upload error');
  } else if (info.file.status === 'removed') {
    fileList.value = fileList.value.filter(items => {
      return items.uid !== info.file.uid
    });
    flushImageList();
  }
  console.log('=====最新 fileList', fileList.value)
  loading.value = false;
};
const uploadHeader = {
  'Access-Token': authState != null ? authState.token : '',
};
// 提交图片
const onFinish2 = async values => {
  loading.value = true;

  // 获取图片链接
  flushImageList();
  if (formState2.act_id == null || formState2.act_id === '') {
    formState2.act_id = actId.value
  }
  if (formState2.id == null || formState2.id ==='') {
    message.error('Please complete the first step first.');
    loading.value = false;
    return false;
  }

  const res = await screenshot(formState2);
  console.log('=====图片提交结果', res);
  if (res.code === 200) {
    setCurrentStep(res.data.status);
  }
  loading.value = false;
};
function flushImageList() {
  formState2.imageList = [];
  fileList.value.forEach(item => {
    formState2.imageList.push(item.url)
  });
}


const formState3 = reactive({
  'ratings': 5
});
// 提交评级
const onFinish3 = async values => {
  loading.value = true;
  if (formState3.act_id == null || formState3.act_id === '') formState3.act_id = actId.value

  if (formState3.id == null || formState3.id ==='') {
    message.error('Please complete the first step first.');
    loading.value = false;
    return false;
  }
  if (formState3.order_no == null || formState3.order_no ==='') {
    message.error('Please complete the second step first.');
    loading.value = false;
    return false;
  }

  const res = await ratings(formState3)
  if (res.code === 200) {
    setCurrentStep(res.data.status)
  }
  loading.value = false;
};


onMounted(async () => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString.substring(1));

  actId.value = params.get('act_id');
  const recordId = params.get('record_id');
  if (actId.value != null && actId.value !== '') {
    localStorage.setItem('actId', actId.value);
  } else {
    actId.value = localStorage.getItem('actId');
  }
  console.log('=======params', actId.value, recordId);

  const res = await recently({id: recordId});
  console.log('res 最新信息：', res.data);
  if (res.code === 200) {
    Object.assign(recordInfo, res.data);
    Object.assign(formState1, res.data);
    Object.assign(formState2, res.data);
    Object.assign(formState3, res.data);

    setCurrentStep(res.data.status);

    let imageList = [];
    if (typeof res.data.screenshot_url != 'undefined' && res.data.screenshot_url !=='') {
      imageList = JSON.parse(res.data.screenshot_url)
    }
    if (Array.isArray(imageList) && imageList.length > 0) {
      let _index = 0;
      imageList.forEach(item => {
        _index++;
        fileList.value.push({
          uid: _index,
          name: 'image: ' + _index,
          status: 'done',
          url: item
        })
      });
    }
  }

  stepLoading.value = false
});
</script>
<style scoped>
.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

[data-theme='dark'] .site-layout-content {
  background: #141414;
}


/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.load-div {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
</style>