<template>
  <a-space direction="vertical" :style="{ width: '100%' }" :size="[0, 48]">
    <a-layout>
      <a-layout-header :style="headerStyle">
        1
        <a-dropdown>
          <a-space>
            <a style="color: #141414; display: inline-block;">{{ userEmail }}</a>
            <a-avatar style="background-color: #87d068">
              <template #icon>
                <UserOutlined />
              </template>
            </a-avatar>
          </a-space>

          <a class="ant-dropdown-link" @click.prevent>
            <DownOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <LogoutOutlined />
                <a-button style="color: #141414" type="link" @click="logout"> Switch Email</a-button>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>

      </a-layout-header>
      <a-layout-content :style="contentStyle">
        <router-view/>
      </a-layout-content>
    </a-layout>
  </a-space>
</template>
<script setup>
import {UserOutlined, LogoutOutlined, DownOutlined} from "@ant-design/icons-vue";
import {inject, ref} from "vue";
import {useAuth} from "@/services/userAuth";

const userEmail = ref('');
const authState = inject('authState');
const showLogin = inject('showLogin');

if (authState != null && typeof authState.email !== 'undefined') {
  userEmail.value = authState.email;
}

const logout = async () => {
  console.log('====logout');
  const res = await useAuth().logout();

  if (res.code === 200) {
    showLogin.value = true;
  }
  console.log('Header page=====', res)
}

const headerStyle = {
  textAlign: 'center',
  color: '#fff',
  height: 60,
  paddingInline: 50,
  lineHeight: '60px',
  backgroundColor: 'rgb(255 255 255)',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 10,
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
};
const contentStyle = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#f5f5f5',
  paddingTop: '80px',
};
const siderStyle = {
  textAlign: 'center',
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#3ba0e9',
};
const footerStyle = {
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#7dbcea',
};
</script>