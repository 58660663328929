// useAuth.js
import http from '../http';
import { reactive } from 'vue';
import {message} from "ant-design-vue";

// 验证邮箱接口，获取邮箱验证码
export async function sendEmailCode(params) {
    try {
        const response = await http.post('/api/send/code', params);
        console.log(response)
        if (response.data.code === 200) {
            message.success('The verification code has been successfully sent');
        } else {
            message.error('Failed to obtain verification code');
        }
        return response.data;
    } catch (error) {
        console.error('Error fetching:', error);
        // throw error;
        message.error('network error');
    }
}

export function useAuth() {
    const authState = reactive({
        isLoggedIn: false,
        email: null, // 存储用户数据的对象或 null
        token: null, // 存储认证令牌的字符串或 null
    });

    // 登录函数
    async function login(params) {
        try {
            const response = await http.post('/api/auth/login', params);
            console.log(response)

            if (response.data.code === 200) {
                message.success('Verification successful');

                authState.isLoggedIn = true;
                authState.email = params.email;
                authState.token = response.data.data.accessToken;

                localStorage.setItem('authState', JSON.stringify(authState));
            } else {
                message.error('Verification failed');
            }

            return response.data;
        } catch (error) {
            console.error('Error fetching:', error);
            // throw error;
            message.error('network error');
        }
    }

    // 登出函数（这里只是清除状态，实际项目中可能需要发送请求到服务器）
    async function logout() {
        console.log('==== logout request ===');
        try {
            const response = await http.post('/api/auth/logout');
            console.log(response)

            if (response.data.code === 200) {
                message.success('Please verify your new email address.');

                authState.isLoggedIn = false;
                authState.email = null;
                authState.token = null;

                localStorage.removeItem('authState');
            } else {
                message.error('Email switching failed');
            }

            return response.data;
        } catch (error) {
            console.error('Error fetching:', error);
            // throw error;
            message.error('network error');
        }
    }

    // 返回登录状态和函数
    return {
        ...authState,
        login,
        logout,
    };
}