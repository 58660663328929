import http from "@/http";
import {message} from "ant-design-vue";

// 收件信息保存接口
export async function recipient(params) {
    try {
        const response = await http.post('/api/free-review/recipient', params);
        if (response.data.code === 200) {
            message.success('The recipient information has been submitted');
        } else {
            message.error(response.data.msg);
        }
        return response.data;
    } catch (error) {
        console.error('Error fetching:', error);
        // throw error;
        message.error('network error');
    }
}

// 图片上传接口
/*export async function uploadImg(params) {
    try {
        const response = await http.post('/api/free-review/upload', params);
        if (response.data.code === 200) {
            message.success('Image uploaded successfully');
        } else {
            message.error('Image upload failed');
        }
        return response.data;
    } catch (error) {
        console.error('Error fetching:', error);
        // throw error;
        message.error('network error');
    }
}*/

// 订单截图保存接口
export async function screenshot(params) {
    try {
        const response = await http.post('/api/free-review/screenshot', params);
        if (response.data.code === 200) {
            message.success('Screenshot submitted');
        } else {
            message.error('Screenshot submitted failed');
        }
        return response.data;
    } catch (error) {
        console.error('Error fetching:', error);
        // throw error;
        message.error('network error');
    }
}


// 评分保存接口
export async function ratings(params) {
    try {
        const response = await http.post('/api/free-review/ratings', params);
        if (response.data.code === 200) {
            message.success('Ratings submitted');
        } else {
            message.error('Ratings submitted failed');
        }
        return response.data;
    } catch (error) {
        console.error('Error fetching:', error);
        // throw error;
        message.error('network error');
    }
}


// 最新领取信息接口
export async function recently(params) {
    try {
        const response = await http.post('/api/free-review/recently', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching:', error);
        // throw error;
        message.error('network error');
    }
}


// 获取
export async function allList(params) {
    try {
        const response = await http.post('/api/free-review/list', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching:', error);
        // throw error;
        message.error('network error');
    }
}